import {
    MEDIUM_TYPE_CARD_TO_CARD,
    MEDIUM_TYPE_PHYSICAL,
    MEDIUM_TYPE_VIRTUAL,
} from '@/prisma/schemaConstants'
import { CUSTOM_CARD_IMAGE } from '@/src/common/constants'
import { formatDateWithoutMonthSpelled } from '@/src/common/utilities/dateFormatters'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    if (!window.gtag) {
        console.debug('window.gtag was not found.')
        return
    }
    window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    if (!window.gtag) {
        console.debug('window.gtag was not found.')
        return
    }
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    })
}

export const eventLeadFormSlideNav = (slideNumber) => {
    if (!window.gtag) {
        console.debug('window.gtag was not found.')
        return
    }
    window.gtag('event', 'leadForm', { slide: slideNumber })
}

export const eventLeadFormExitToGCG = (redirectUrl) => {
    if (!window.gtag) {
        console.debug('window.gtag was not found.')
        return
    }
    window.gtag('event', 'leadFormExitToGCG', {
        event_callback: function () {
            window.location.href = redirectUrl
        },
    })
}

export const sampleToastClick = () => {
    if (!window.gtag) {
        console.debug('window.gtag was not found.')
        return
    }
    window.gtag('event', 'sampleRequestClick')
}

export const sampleRequestSubmitted = () => {
    if (!window.gtag) {
        console.debug('window.gtag was not found.')
        return
    }
    window.gtag('event', 'sampleRequestSubmit')
}

export const VisaBACVisited = (
    eventNames,
    mediumType = 'Build A Card',
    designTypeOrName = '',
    denomination = '0',
    cardQuantity = 1,
    isBox = false
) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    eventNames.map((eventName) => {
        window.dataLayer.push({
            event: eventName,
            ecommerce: {
                detail: {
                    products: [
                        {
                            id: `Visa ${mediumType}`,
                            name: `Visa ${isBox ? 'boxset' : mediumType}`,
                            category: mediumType,
                            brand: `Visa`,
                            price: denomination
                                ? parseFloat(denomination)
                                : null,
                            item_variant: designTypeOrName,
                            quantity: cardQuantity,
                        },
                    ],
                },
            },
        })
    })
}

export const PGPlusBACVisited = (
    eventNames,
    brandName,
    designTypeOrName = '',
    isFromBrandPage = false,
    brandCategories = [],
    denomination = '0',
    cardQuantity = 1
) => {
    let product = {
        item_id: `${brandName} virtual`,
        item_name: `${brandName} virtual`,
        item_brand: brandName,
        item_category: 'virtual',
        item_list_name: isFromBrandPage ? 'Brands Product List' : null,
        item_variant: designTypeOrName,
        quantity: cardQuantity,
        price: denomination ? parseFloat(denomination) : null,
    }

    if (brandCategories) {
        const categories = {
            item_category2: brandCategories[0] ?? '',
            item_category3: brandCategories[1] ?? '',
            item_category4: brandCategories[2] ?? '',
            item_category5: brandCategories[3] ?? '',
        }
        product = { ...product, ...categories }
    }

    eventNames.map((eventName) => {
        window.dataLayer.push({
            event: eventName,
            ecommerce: {
                detail: {
                    products: [product],
                },
            },
        })
    })
}

export const dataLayerAddVisaToCart = (
    eventNames,
    mediumType,
    denomination,
    cardQuantity,
    designTypeOrName = '',
    isBox = false
) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    eventNames.map((eventName) => {
        window.dataLayer.push({
            event: eventName,
            ecommerce: {
                add: {
                    products: [
                        {
                            id: `Visa ${mediumType}`,
                            name: `Visa ${isBox ? 'boxset' : mediumType}`,
                            category: mediumType,
                            price: parseFloat(denomination),
                            brand: 'Visa',
                            quantity: cardQuantity,
                            item_variant: designTypeOrName,
                        },
                    ],
                },
            },
        })
    })
}

export const dataLayerAddPGPlusToCart = (
    eventNames,
    denomination,
    designTypeOrName = '',
    brandName = '',
    brandCategories = [],
    isFromBrandPage = false,
    cardQuantity = 1
) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    const mediumType = MEDIUM_TYPE_VIRTUAL
    let products = {
        item_id: `${brandName} ${mediumType}`,
        item_name: `${brandName} ${mediumType}`,
        item_brand: brandName,
        item_category: mediumType,
        item_list_name: isFromBrandPage ? 'Brands Product List' : null,
        item_variant: designTypeOrName,
        price: parseFloat(denomination),
        quantity: cardQuantity,
    }

    if (brandCategories) {
        const categories = {
            item_category2: brandCategories[0] ?? '',
            item_category3: brandCategories[1] ?? '',
            item_category4: brandCategories[2] ?? '',
            item_category5: brandCategories[3] ?? '',
        }
        products = { ...products, ...categories }
    }

    eventNames.map((eventName) => {
        window.dataLayer.push({
            event: eventName,
            ecommerce: {
                add: {
                    products: [products],
                },
            },
        })
    })
}

// this is sent only once when the user visits the checkout page
export const enteredCheckout = (products) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            checkout: {
                actionField: { step: 1 },
                products: products,
            },
        },
    })
}

// is sent when user is adding payment info
// will get resent if cart data updates
export const addPaymentStepAnalyticEvent = (products) => {
    if (!window.dataLayer || !products) {
        console.debug('window.dataLayer was not found.')
        return
    }

    window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step: 'checkout_payment_step',
                },
                products: products,
            },
        },
    })
}

export const userCompletedCheckout = (
    orderNumber,
    revenue,
    discount,
    tax,
    shipping,
    products,
    user
) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    const { buyerFirstName, buyerLastName, email } = user
    window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
            purchase: {
                actionField: {
                    id: orderNumber,
                    affiliation: 'Online Store',
                    revenue: parseFloat(revenue),
                    discount: parseFloat(discount),
                    tax: parseFloat(tax),
                    shipping: parseFloat(shipping),
                },
                products: products,
            },
        },
        buyerFirstName: buyerFirstName,
        buyerLastName: buyerLastName,
        buyerEmail: email,
    })
}

export const gcgReferral = () => {
    if (!window.gtag) {
        console.debug('window.gtag was not found.')
        return
    }
    window.gtag('event', 'leadFormQuantityGCGReferral')
}

export const basicGtagEvent = (event) => {
    if (!window.gtag) {
        console.debug('window.gtag was not found.')
        return
    }
    window.gtag('event', event)
}

export const leadFormVariantView = (event, variant) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }
    window.dataLayer.push({
        event: event,
        variant: variant,
    })
}

export const addPaymentInfoAnalyticEvent = (orderValue) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    window.dataLayer.push({
        event: 'add_payment_info',
        currency: 'USD',
        value: parseFloat(orderValue),
        payment_type: 'Credit Card',
    })
}

export const addSelectItemAnalyticEvent = (brandName, brandCategories) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }
    let product = {
        item_id: `${brandName} virtual`,
        item_name: `${brandName} virtual`,
        item_brand: brandName,
        item_category: 'virtual',
    }

    if (brandCategories) {
        const categories = {
            item_category2: brandCategories[0] ?? '',
            item_category3: brandCategories[1] ?? '',
            item_category4: brandCategories[2] ?? '',
            item_category5: brandCategories[3] ?? '',
        }
        product = { ...product, ...categories }
    }

    window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
            item_list_name: 'Brands Product List',
            items: [product],
        },
    })
}

/**
 * @param cartTotal
 * @param formattedCartItems Use buildProductsForDataLayerFromCart to format ajax Cart to GA4 items
 */
export const showCartEvent = (cartTotal, formattedCartItems) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
            currency: 'USD',
            value: parseFloat(cartTotal),
            items: formattedCartItems,
        },
    })
}

export const addItemRemovedFromCartAnalyticEvent = (cartItem, mediumType) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    const brandName =
        cartItem?.customization_data?.selected_gyp_brand?.resolved_data
            ?.brand_name ?? cartItem?.product?.resolved_data?.product_brand
    const medium =
        mediumType === MEDIUM_TYPE_CARD_TO_CARD ? 'virtual' : mediumType
    const itemValue = cartItem?.denomination
    const brandCategories =
        cartItem?.customization_data?.selected_gyp_brand?.brand_categories ?? []
    const designTypeOrName = cartItem?.custom_design?.preview_image_url
        ? CUSTOM_CARD_IMAGE
        : cartItem?.design?.resolved_data?.design_name ?? ''
    const denomination = cartItem?.denomination

    let item = {
        item_id: `${brandName} ${medium}`,
        item_name: `${brandName} ${medium}`,
        item_brand: brandName,
        item_category: medium,
        item_list_name: null,
        item_variant: designTypeOrName,
        price: parseFloat(denomination),
        quantity: 1,
    }

    if (brandCategories) {
        const categories = {
            item_category2: brandCategories[0] ?? '',
            item_category3: brandCategories[1] ?? '',
            item_category4: brandCategories[2] ?? '',
            item_category5: brandCategories[3] ?? '',
        }
        item = { ...item, ...categories }
    }

    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            currency: 'USD',
            value: parseFloat(itemValue),
            items: [item],
        },
    })
}

export const addBoxRemovedFromCartAnalyticEvent = (items) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    const quantity = items?.length
    const brandName = items[0]?.product?.resolved_data?.product_brand
    const medium = MEDIUM_TYPE_PHYSICAL
    const itemValue = (parseFloat(items[0]?.denomination) * quantity).toFixed(2)
    const designTypeOrName = items[0]?.custom_design?.preview_image_url
        ? CUSTOM_CARD_IMAGE
        : items[0]?.design?.resolved_data?.design_name ?? ''

    let item = {
        item_id: `${brandName} ${medium}`,
        item_name: `${brandName} boxset`,
        item_brand: brandName,
        item_category: medium,
        item_list_name: null,
        item_variant: designTypeOrName,
        price: itemValue,
        quantity: quantity,
    }

    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            currency: 'USD',
            value: parseFloat(itemValue),
            items: [item],
        },
    })
}

export const viewList = (listName, flow, items) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    function assembleItems() {
        if (!items) {
            return []
        }

        return items?.map((item) => {
            const brandName = item?.name ?? ''
            const medium = 'virtual'
            const brandCategories = item?.categories ?? null

            let categoriesForItem = null

            if (brandCategories) {
                categoriesForItem = {
                    item_category2: brandCategories[0] ?? '',
                    item_category3: brandCategories[1] ?? '',
                    item_category4: brandCategories[2] ?? '',
                    item_category5: brandCategories[3] ?? '',
                }
            }

            return {
                item_id: `${brandName} ${medium}`,
                item_name: `${brandName} ${medium}`,
                item_brand: brandName,
                item_category: medium,
                ...categoriesForItem,
                item_list_name: listName,
            }
        })
    }

    window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
            id: flow,
            item_list_name: listName,
            items: assembleItems(),
        },
    })
}

export const trackUnwrapEvent = (gift) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    window.dataLayer.push({
        event: 'unwrapPerfectGift',
        senderGuid: gift.senderGuid,
        giftId: gift.giftId,
        giftSendDate: formatDateWithoutMonthSpelled(gift?.giftSendDate),
        guid: gift.guid,
        wrapType: gift.wrapType ? 'custom' : 'predesign',
        branded: gift.isBranded ? 'branded' : 'nonbranded',
    })
}

export const sendBasicEvent = (eventName, data = {}) => {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    window.dataLayer.push({ event: eventName, ...data })
}

// send in 'virtual', 'plastic', etc.
export function changeProductEvent(type) {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    window.dataLayer.push({
        event: 'productChange',
        context: `change to ${type}`,
    })
}

// send in 'related brand' or 'gift idea' AND brand name
export function clickedRelatedItemEvent(type, brandName) {
    if (!window.dataLayer) {
        console.debug('window.dataLayer was not found.')
        return
    }

    window.dataLayer.push({
        event: 'relatedProduct',
        context: type,
        item: brandName,
    })
}
