export default function cardLimitValidator({
    cartData,
    isEditing,
    quantityToEditFromCart,
}: {
    cartData: any
    isEditing: boolean
    quantityToEditFromCart: number
}) {
    const items = cartData?.cart_contents?.deliveries
    let results = { total: 0, numberOfCards: 0 }

    items?.map((card: { line_items: any }) => {
        const lineItems = card.line_items
        lineItems.map(
            (item: {
                product: {
                    resolved_data: { product_medium: { medium_name: any } }
                }
                resolved_data: { item_price: string }
            }) => {
                results.total += parseFloat(
                    item.resolved_data?.item_price ?? '0'
                )
                results.numberOfCards = results.numberOfCards + 1
            }
        )
    })

    // remove a card from the total number of cards if editing
    if (isEditing) {
        results.numberOfCards = results.numberOfCards - quantityToEditFromCart
    }

    return results
}
